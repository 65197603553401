// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_ADMIN = "SET_ADMIN";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const LOADING_FOLDER_SEARCH = "LOADING_FOLDER_SEARCH";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_DATA = "STOP_LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const STOP_LOADING_FOLDER_SEARCH = "STOP_LOADING_FOLDER_SEARCH";
// Data reducer types
// Images
// export const POST_IMAGE = "POST_IMAGE";
export const GET_BANNER_IMAGE = "GET_BANNER_IMAGE";
export const PATCH_BANNER_IMAGE = "PATCH_BANNER_IMAGE";
// Announcements
export const SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS";
export const POST_ANNOUNCEMENT = "POST_ANNOUNCEMENT";
export const PATCH_ANNOUNCEMENT = "PATCH_ANNOUNCEMENT";
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
export const FILTER_ANNOUNCEMENTS = "FILTER_ANNOUNCEMENTS";
// Departments
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const POST_DEPARTMENT = "POST_DEPARTMENT";
export const PATCH_DEPARTMENT = "PATCH_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
// Contacts
export const SET_CONTACTS = "SET_CONTACTS";
export const POST_CONTACT = "POST_CONTACT";
export const PATCH_CONTACT = "PATCH_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const SEARCH_CONTACTS = "SEARCH_CONTACTS";
// Folders
export const ADD_SUBFOLDER = "ADD_SUBFOLDER";
export const PATCH_FOLDER = "PATCH_FOLDER";
export const PATCH_SUBFOLDER = "PATCH_SUBFOLDER";
export const DELETE_SUBFOLDER = "DELETE_SUBFOLDER";
export const SORT_SUBFOLDER = "SORT_SUBFOLDER";
export const MOVE_SUBFOLDER = "MOVE_SUBFOLDER";
export const SET_NAV_PATH = "SET_NAV_PATH";
export const RESET_NAV_PATH = "RESET_NAV_PATH";
export const SET_FOLDER_SEARCH_RES = "SET_FOLDER_SEARCH_RES";
// Data Handling
export const SET_DATA_ARRAY = "SET_DATA_ARRAY";
export const SET_DATA = "SET_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const POST_DATA = "POST_DATA";
